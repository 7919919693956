@import 'variables';

@font-face {
    font-family: "Century Gothic";
    src: local("Century Gothic"), local("CenturyGothic"),
        url("../../fonts/GOTHIC.TTF") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Century Gothic";
    src: local("Century Gothic"), local("CenturyGothic"),
       url("../../fonts/GOTHICB.TTF") format("truetype");
    font-weight: bold;
    font-style: normal;
}

*, body {
    font-family: "Century Gothic", sans-serif;
}