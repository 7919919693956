@import 'variables';

$prefix: $antPrefix + -transfer;

.#{$prefix} {
    .#{$prefix}-list {
        width: 100%;

        .#{$prefix}-list-body {
            .#{$prefix}-list-content-item-remove {
                &:hover {
                    color: $errorColor;
                }
            }
        }
    }
}