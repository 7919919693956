@import 'variables';

$prefix: $antPrefix + -card;

.#{$prefix} {
  background: $componentBackground;
  color: $textColor;
  border-radius: 5px;
  padding: 0px 16px;
  &-head,
  &-meta-title,
  &-meta-description {
    color: $textColor;
  }
  &-head {
    border-bottom-color: $borderColor;
  }
  &-bordered {
    border-color: $borderColor;
  }
}

.#{$prefix} {
  border: 0;
}

.#{$prefix}-head-title {
  text-align: center;
  font-size: 1.1em;
}

.#{$prefix}-head {
  border-bottom-color: $componentBackground;
}

.#{$prefix}-body {
  padding:  16px;

  .#{$antPrefix}-divider-horizontal {
    border-top: 1px solid $clientColor;
    margin: 0;
    position: relative;
    top: -15px;
    min-width: 0%;
    width: 70%;
    left: 50%;
    transform: translateX(-50%);
  }
}

.ant-col-7 .#{$prefix} {
  background: $clientColor;
  color: $textColorSecondary;
  border-radius: 5px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.3);

  .#{$antPrefix}-divider-horizontal {
    border-top: 1px solid $componentBackground;
  }
}

.ant-col-7 .#{$prefix}-head {
  border-bottom-color: $clientColor;
  color: $textColorSecondary;

  &-title {
    position: relative;
    left: 9%;
  }

  .#{$prefix}-extra {
    position: relative;
    right: -8%;
  }
}

.#{$prefix}-body {

  .#{$antPrefix}-comment-content-author-name, .#{$antPrefix}-comment-content-author-time {
    text-transform: uppercase;
    font-size: 1em;
  }

  .#{$antPrefix}-comment-content-author-name {
    color: $secondaryColor;
    letter-spacing: .1em;
  }

  .#{$antPrefix}-comment-content-author-time {
    color: #9c9c9c;
    letter-spacing: .05em;
  }
}  

.#{$antPrefix}-list .#{$prefix} {

  .#{$prefix}-body {
    padding: 10px;
  }
}

#planing .ant-tabs .#{$prefix} {

  .#{$prefix}-head-title {
    position: relative;
    left: 10%;
  }

  .#{$prefix}-extra {
    position: relative;
    right: -5%;
  }
}

.gantt-card {
  max-width: 100%;
  overflow-x: auto;
}

.perimeter-users-card.#{$prefix} {
  box-shadow: none;
  color: $textColor;
  font-size: 1.1em;
  padding: 0;
  margin: 0 0 24px 0;

  .#{$prefix}-head-wrapper {
    position: relative;

    .#{$prefix}-extra {
      position: absolute;
      right: 0;
      top: 0;
    }
  }
}

.supervisor-project-card {
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);

  .project-card-header {
    position: relative;

    .gantt-project-version {
      position: absolute;
      right: 0;
    }
  }
}