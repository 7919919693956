@import 'variables';

.custom-header {
    margin: 10px 0px 16px 0;
    padding: 12px;
    background: $clientColor;
    border-radius: 5px;
    color: $textColorSecondary;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    gap: 10px;
    align-items: center;
  }

  .notification-container {
    .ant-menu-item {
      transition: background-color 0.3s;
      padding: 0;
  
      &:hover {
        background-color: red;
      }
  
      &.notification-grayed {
        background-color: #f0f0f0;
      }
    }
  
    .ant-divider {
      margin: 0;
    }
  }

  .notification-card {
    transition: background-color 0.3s;
  
    &:hover {
      background-color: #f0f0f0;
    }
  }

  .green-circle {
    width: 10px;
    height: 10px;
    background-color: green;
    border-radius: 50%;
  }

  .styled-dropdown-container {
    height: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 16px;
    transition: all 0.3s;
  
    &:hover {
      background: $contrastBackground;
    }
  }