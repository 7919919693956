@import 'variables';

$prefix: $antPrefix + -tabs;

.#{$prefix} {
  color: $textColorSecondary;
  > .#{$prefix}-nav::before,
  &-bar {
    border-bottom-color: $bodyBackground;
  }
  &-content {
    color: $textColor;
  }

  &-nav .#{$prefix}-tab {
    &-active,
    &:hover, &:focus {
      color: $primaryColor;
    }
    &-disabled,
    &-disabled:hover {
      color: $disabledColor;
    }
  }

  &-ink-bar {
    background-color: $primaryColor;
  }
}

.#{$prefix} {
  margin-right: 15px;
}

.#{$prefix}-top > .#{$prefix}-nav {
  background: $clientColor;
  border-radius: 5px;
  margin-bottom: 16px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  min-height: 58px;

  .#{$prefix}-nav-list {
    width: 100%;
  }

  .#{$prefix}-tab {
    margin: auto;
    width: auto;
  }

  .#{$prefix}-tab div {
    font-size: 14px;
    text-align: center;
  }

  .#{$prefix}-tab-btn > span:last-child {
    max-width: 25px;
    margin-right: 0;
  }
}

.#{$prefix}-tab {
  padding: 10px;
  margin-right: 12px;
}

.#{$prefix}-tab.#{$prefix}-tab-active .#{$prefix}-tab-btn {
  color: $textColorSecondary;
  font-weight: 700;
}

.#{$prefix} > .#{$prefix}-nav::before, .#{$prefix}-bar {
  border: 0;
}

.#{$prefix}-nav .#{$prefix}-nav-list .#{$prefix}-tab:not(.#{$prefix}-tab-active) .#{$prefix}-tab-btn:focus { color: $primaryColor; }

.#{$prefix}-extra-content .#{$antPrefix}-input-search .#{$antPrefix}-input-group .#{$antPrefix}-input-affix-wrapper:not(:last-child),
.#{$prefix}-extra-content .#{$antPrefix}-input,
.#{$prefix}-extra-content .#{$antPrefix}-btn:not([disabled]):not(.#{$antPrefix}-btn-primary):not(.#{$antPrefix}-btn-add):not(.#{$antPrefix}-btn-danger):not(.#{$antPrefix}-btn-dangerous):not(.#{$antPrefix}-btn-link):not(.#{$antPrefix}-dropdown-trigger) {
  background-color: #c8d1d6;
  border-color: #9eafb7;
  color: $textColor;

  &::placeholder {
    color: #536770;
  }
}

.#{$prefix}-extra-content .#{$antPrefix}-btn:not([disabled]):not(.#{$antPrefix}-btn-primary):not(.#{$antPrefix}-btn-add):not(.#{$antPrefix}-btn-danger):not(.#{$antPrefix}-btn-dangerous):not(.#{$antPrefix}-btn-link):not(.#{$antPrefix}-dropdown-trigger) {
  border-radius: 0 5px 5px 0;
}

.#{$prefix}-right > .#{$prefix}-nav .#{$prefix}-tab .#{$prefix}-tab-btn {
  color: $textColor;
  text-shadow: none;

  &:hover {
    color: $primaryColor;
  }
}
.#{$prefix}-left > .#{$prefix}-nav .#{$prefix}-tab .#{$prefix}-tab-btn {
  color: $textColor;
  text-shadow: none;

  &:hover {
    color: $primaryColor;
  }
}

.#{$prefix}-tab .anticon {
  margin-right: 0;
}

.#{$prefix}-large > .#{$prefix}-nav .#{$prefix}-tab {
  padding: 10px 0;
}

.#{$prefix}:not(.#{$prefix}-large) .#{$prefix}-tab-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;

  span:not(last-child):not(.ant-scroll-number-only) {
    margin-right: 1em;
  }
}

#blue-tab-risks .#{$prefix}-nav, #blue-tab-planning .#{$prefix}-nav, #blue-tab-utility .#{$prefix}-nav {
  background: #15afe2;
  min-height: 42px;
}

.#{$antPrefix}-modal .#{$prefix}-nav {
  min-height: 42px;
}

.#{$prefix}.show-project-tabs  > .#{$prefix}-nav .#{$prefix}-nav-list {
  flex-wrap: wrap;
  .#{$prefix}-tab {
    padding: 10px 2px;
  }
}

@media (max-width: 660px) {
  .#{$prefix}.show-project-tabs > .#{$prefix}-nav .#{$prefix}-nav-list {
    .#{$prefix}-ink-bar {
      display: none;
    }
    .#{$prefix}-tab-active .#{$prefix}-tab-btn {
      color: $primaryColor;
    } 
  }
}